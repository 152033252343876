import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaLayout } from 'use-media'
import theme from '../styles/theme'

/*
 * The <Accordion> component returns a <li> element and should be used inside an <ol> or <ul>
 * The `props.children` of <Accordion>s should be within a single <div> or <ul>
 * A nested <Accordion> will work inside another <Accordion> when the parent element is <ul>
 * <Accordion> can optionally receive an SVG icon as a collapsed/expanded indicator
 */

/**
 * @example
 * <ListOfItems> // a <ul> element
 *   {items.map(item => (
 *       <Accordion
 *         key={item.id}
 *         title={item.title}
 *         titleClass="section-title--secondary"
 *         toggleSVG={<ArrowUp width="14px" />}
 *       >
 *         <div
 *           onClick={e => e.stopPropagation()}
 *           dangerouslySetInnerHTML={{
 *             __html: item.body.childMarkdownRemark.html,
 *           }}
 *         />
 *       </Accordion>
 *   ))}
 * </ListOfItems>
 */

const Outer = styled.li`
  > div,
  > ul {
    pointer-events: none;
  }

  &.is-expanded {
    > div,
    > ul {
      pointer-events: initial;
    }
  }
`

const ToggleIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: right;
  padding: 8px 0 2px;
  // @ts-ignore
  color: ${props => (props.svgColor ? props.svgColor : null)};
`

const Accordion = ({ title, children, ...props }) => {
  const simpleKebabCase = string =>
    string
      .split(/[_\s]/)
      .join('-')
      .toLowerCase()

  const windowWidthDesktop = useMediaLayout({
    minWidth: theme.responsive.large,
  })

  const [visibility, setVisibility] = useState(false)

  return (
    <Outer
      {...props}
      className={
        props.alwaysExpanded ||
        (props.expandedOnDesktop && windowWidthDesktop) ||
        visibility
          ? 'is-expanded'
          : undefined
      }
      onClick={() => {
        setVisibility(!visibility)
      }}
    >
      <h4
        className={props.titleClass}
        id={props.hrefId ? simpleKebabCase(title) : null}
      >
        {props.toggleSVG && !(props.expandedOnDesktop && windowWidthDesktop) ? (
          <ToggleIcon {...props}>{props.toggleSVG}</ToggleIcon>
        ) : null}
        {title}
      </h4>
      {children}
    </Outer>
  )
}

export default Accordion
