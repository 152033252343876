import React from 'react'
import styled from 'styled-components'

/*
 * The <ResourcesList> component returns a <ul> element and should be used around <li> element(s)
 * The <li> element(s) are received as `props.children`
 */

/**
 * @example
 * <ResourcesList>
 *   {cardItems.map(item => (
 *     <Card key={item.id} {...item} /> // <li> element(s)
 *   ))}
 * </ResourcesList>
 */

const List = styled.ul`
  > li {
    padding: 10px 0;
    background-color: white;
    cursor: pointer;

    > h4 {
      margin-bottom: 0;
    }

    > div {
      height: 0;
      opacity: 0;
      transform: translate3d(0, -40px, 0) scale(0.98);
      transition: 0ms;
      cursor: initial;
    }

    &.is-expanded {
      padding-bottom: 15px;

      > h4 {
        margin-bottom: 7px;
      }

      > div {
        height: auto;
        transform: translate3d(0, 0, 0) scale(1);
        opacity: 1;
        transition: opacity 200ms ease-in, transform 120ms ease-out;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    > li {
      padding: 15px 0;

      &:last-of-type {
        padding-bottom: 0;
      }

      &.is-expanded {
        > h4 {
          margin-bottom: 10px;
        }
      }
    }
  }
`

const FaqItems = ({ children, ...props }) => {
  return <List {...props}>{children}</List>
}

export default FaqItems
