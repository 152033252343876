import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Accordion from '../../components/Accordion'
import FaqItems from '../../components/FaqItems'
import ArrowUp from '../../images/arrow--caret-up.svg'

const FAQsCategoriesList = styled.ul`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  > li {
    padding: 20px 0;
    background-color: white;
    cursor: pointer;
    border-top: 1px solid ${(props) => props.theme.colors.lightBorder};

    &:first-of-type {
      padding-top: 0;
      border-color: transparent;
    }

    > ul {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transform: translate3d(0, -40px, 0) scale(0.98);
      transition: 0ms;
      cursor: initial;
    }

    > h4 {
      position: relative;
      z-index: 1;
      margin-bottom: 0;
      text-align: left;
      background-color: white;

      svg {
        width: 14px;
        transform: rotate(180deg);
        transition: transform 120ms ease-out;
      }
    }

    &.is-expanded {
      padding-bottom: 10px;

      > h4 {
        margin-bottom: 15px;

        svg {
          transform: rotate(0);
        }
      }
      > ul {
        height: auto;
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
        transition: opacity 200ms ease-in, transform 120ms ease-out;
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    > li {
      padding: 60px 0;

      &.is-expanded {
        padding-bottom: 60px;
      }

      > h4 {
        svg {
          width: 18px;
        }
      }
    }
  }
`

const Support = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(sort: { fields: [type, order], order: [ASC, ASC] }) {
        edges {
          node {
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            title
            type
          }
        }
      }
    }
  `)

  const rawFaqs = data.allContentfulFaq.edges
  // filter out certain categories
  const faqs = rawFaqs.filter((faq) =>
    faq.node.type !== 'Virtual Event' &&
    faq.node.type !== 'Rewards' &&
    faq.node.type !== 'Reward Trips'
  )

  const sortedFaqs = (faqs) => {
    const sortedFaqs = {}

    for (const faq of faqs) {
      if (faq.node?.type) {
        const key = `${faq.node.type.replace(' ', '_')}`
        if (!sortedFaqs[key]) {
          sortedFaqs[key] = []
        }
        sortedFaqs[key].push(faq)
      }
    }
    const faqByType = []
    for (const faqs of Object.values(sortedFaqs)) {
      const faqObj = {
        title: faqs[0].node.type,
        faqs,
      }
      faqByType.push(faqObj)
    }

    // The graphql payload comes back sorted alphabetically by type
    // We want to have 'General' type first, then the rest alphabetically sorted
    // 1. Find the index of 'General' type FAQs
    const generalFaqIndex = faqByType.findIndex(
      (faqObj) => faqObj.title === 'General'
    )
    // 2. Move the 'General' type faqs object to the beginning of the array
    faqByType.unshift(faqByType.splice(generalFaqIndex, 1)[0])
    return faqByType
  }

  const faqsByType = sortedFaqs(faqs)

  return (
    <FAQsCategoriesList>
      {faqsByType.map((faqByType) => (
        <Accordion
          expandedOnDesktop="true"
          key={`${faqByType.title}${faqByType.faqs[0].node.id}`}
          title={
            faqByType.title === 'Reunion'
              ? 'Over the Rainbow'
              : faqByType.title === 'Incentive Trip'
                ? 'Watercolor'
                : faqByType.title
          }
          titleClass="section-title"
          toggleSVG={<ArrowUp />}
          hrefId="true"
        >
          <FaqItems onClick={(e) => e.stopPropagation()}>
            {faqByType.faqs.map(({ node: faq }) => (
              <Accordion
                key={faq.id}
                title={faq.title}
                titleClass="section-label"
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  dangerouslySetInnerHTML={{
                    __html: faq.body.childMarkdownRemark.html,
                  }}
                />
              </Accordion>
            ))}
          </FaqItems>
        </Accordion>
      ))}
    </FAQsCategoriesList>
  )
}

export default Support
